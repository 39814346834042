export class FibonacciChecker {
  private fibonacciSequence: number[];

  constructor() {
    // initialize with only the first two values
    // it will grow as needed when larger values are checked0
    this.fibonacciSequence = [1, 1];
  }

  growSequenceForValue(value: number) {
    // if the highest number in our calculated sequence is lower than the incoming value
    // grow the sequence until this is not the case anymore
    while (this.fibonacciSequence[this.fibonacciSequence.length - 1] < value) {
      this.fibonacciSequence.push(
        this.fibonacciSequence[this.fibonacciSequence.length - 1] +
          this.fibonacciSequence[this.fibonacciSequence.length - 2]
      );
    }
  }

  checkSequence(sequence: number[]) {
    // make sure our calculated sequence is large enough for the incoming sequence values
    sequence.forEach((value) => this.growSequenceForValue(value));

    // each found fibonacci sequence will add an item to results
    const results: { start: number; end: number; values: number[] }[] = [];

    for (let i = 0; i < sequence.length; i++) {
      // loop through values to find the start of a sequence
      let startValue = sequence[i];
      const startIndex = this.fibonacciSequence.indexOf(startValue);

      if (startIndex !== -1) {
        // if value is in fibonacci sequence
        // create an item to add to results array
        const result = {
          start: i,
          values: [startValue],
          end: i,
        };
        let currentFibonacciIndex = startIndex;

        // loop through values to find the end of the sequence
        for (let j = i + 1; j < sequence.length; j++) {
          const value = sequence[j];
          currentFibonacciIndex++;

          // edge case for sequence 1,2
          // because this.fibonacciSequence.indexOf(1) does not return the
          // index of the second 1 in the fibonacci sequence
          if (startValue === 1 && j === i + 1 && value === 2) {
            currentFibonacciIndex++;
          }

          // if the value of this item is the next Fibonacci number
          if (value === this.fibonacciSequence[currentFibonacciIndex]) {
            // add it to the result
            result.values.push(value);
            result.end = j;
          } else {
            // otherwise stop iterating
            break;
          }
        }

        results.push(result);
      }
    }
    return results;
  }
}
