import { Grid } from "./components/Grid";

function App() {
  return (
    <div className="App">
      <div className="inline-flex p-10">
        <Grid rows={50} columns={50} />
      </div>
    </div>
  );
}

export default App;
