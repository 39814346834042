import {
  FC,
  HTMLProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Cell } from "./Cell";
import { FibonacciChecker } from "../utils/FibonacciChecker";

export const Grid: FC<
  { rows: number; columns: number } & HTMLProps<HTMLDivElement>
> = ({ rows, columns, ...props }) => {
  const [cells, setCells] = useState<CellData[]>([]);
  const fibonacciChecker = useMemo(() => new FibonacciChecker(), []);

  useEffect(() => {
    // initialize cells with value 0 when row or column size changes
    const newCells: CellData[] = [];
    for (let y = 0; y < rows; y++) {
      for (let x = 0; x < columns; x++) {
        newCells.push({ x, y, value: 0, highlighted: false });
      }
    }
    setCells(newCells);
  }, [rows, columns]);

  const handleCellClick = (cell: CellData) => {
    incrementRowValues(cell.y);
    incrementColumnValues(cell.x);
  };

  const handleCellRightClick = (cell: CellData, e: React.MouseEvent) => {
    decrementRowValues(cell.y);
    decrementColumnValues(cell.x);
    e.preventDefault(); // prevent context menu from showing up
  };

  const setCellValue = (cellToChange: CellData, value: number) => {
    setCells((cells) =>
      cells.map((cell) => {
        if (cell.x === cellToChange.x && cell.y === cellToChange.y) {
          return {
            ...cell,
            value: Math.max(0, value),
          };
        }
        return cell;
      })
    );
  };

  const resetCells = (cellsToReset: CellData[]) => {
    // highlight the cells for a second, then set value to 0

    // set highlighted = true
    setCells((cells) =>
      cells.map((cell) =>
        cellsToReset.findIndex(
          (foundCell) => foundCell.x === cell.x && foundCell.y === cell.y
        ) === -1
          ? cell
          : { ...cell, highlighted: true }
      )
    );

    // then after 1 second, set highlighted = false and value = 0
    setTimeout(
      () =>
        setCells((cells) =>
          cells.map((cell) =>
            cellsToReset.findIndex(
              (foundCell) => foundCell.x === cell.x && foundCell.y === cell.y
            ) === -1
              ? cell
              : { ...cell, highlighted: false, value: 0 }
          )
        ),
      1000
    );
  };

  const addToCellValue = (cell: CellData, value: number) => {
    setCellValue(cell, cell.value + value);
  };

  const incrementCellValue = (cell: CellData) => addToCellValue(cell, 1);

  const decrementCellValue = (cell: CellData) => addToCellValue(cell, -1);

  const incrementRowValues = (y: number) =>
    getCellsForRow(y).forEach(incrementCellValue);

  const decrementRowValues = (y: number) =>
    getCellsForRow(y).forEach(decrementCellValue);

  const incrementColumnValues = (x: number) =>
    getCellsForColumn(x).forEach(incrementCellValue);

  const decrementColumnValues = (x: number) =>
    getCellsForColumn(x).forEach(decrementCellValue);

  const getCellsForRow = useCallback(
    (y: number) => cells.filter((cell) => cell.y === y),
    [cells]
  );

  const getCellsForColumn = useCallback(
    (x: number) => cells.filter((cell) => cell.x === x),
    [cells]
  );

  const getAllRows = useCallback(
    () => Array.from({ length: rows }, (_, y) => getCellsForRow(y)),
    [rows, getCellsForRow]
  );

  const getAllColumns = useCallback(
    () => Array.from({ length: columns }, (_, x) => getCellsForColumn(x)),
    [columns, getCellsForColumn]
  );

  useEffect(() => {
    // when cells change, check for fibonacci matches

    // check rows in both directions
    getAllRows().forEach((cells) => {
      checkCellsForFibonacci([...cells].sort((a, b) => a.x - b.x));
      checkCellsForFibonacci([...cells].sort((a, b) => b.x - a.x));
    });
    // check columns in both directions
    getAllColumns().forEach((cells) => {
      checkCellsForFibonacci([...cells].sort((a, b) => a.y - b.y));
      checkCellsForFibonacci([...cells].sort((a, b) => b.y - a.y));
    });

    function checkCellsForFibonacci(cells: CellData[]) {
      // array of results containing start, end and values for each matching sequence
      const results = fibonacciChecker.checkSequence(
        cells.map((cell) => cell.value)
      );

      results.forEach(({ start, end, values }) => {
        if (values.length >= 5) {
          // if sequence is of length 5 or longer
          // reset corresponding cells
          const foundCells = cells.slice(start, end + 1);
          resetCells(foundCells);
        }
      });
    }
  }, [cells, getAllRows, getAllColumns, fibonacciChecker]);

  return (
    <div className="inline-flex flex-col" {...props}>
      {getAllRows().map((row, y) => {
        return (
          <div className="inline-flex min-w-full" key={`row-${y}`}>
            {row.map((cell) => {
              return (
                <Cell
                  key={`cell-${cell.x}-${cell.y}`}
                  cell={cell}
                  onClick={() => handleCellClick(cell)}
                  onContextMenu={(e) => handleCellRightClick(cell, e)}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
