import { FC, HTMLProps } from "react";

export const Cell: FC<{ cell: CellData } & HTMLProps<HTMLDivElement>> = ({
  cell,
  ...props
}) => {
  const { highlighted, value } = cell;
  return (
    <div
      className={`
      w-8
      h-8
      overflow-clip
      inline-flex
      justify-center
      items-center
      transition-colors
      duration-500
      ease-in-out
      ${highlighted ? "text-2xl" : "text-base"}
      ${highlighted || value > 57 ? "text-white" : "text-black"}
      cursor-pointer
      select-none
      border-black
      hover:border-2
      `}
      style={{
        backgroundColor: highlighted
          ? "#ff0000"
          : `hsl(${value * 10}, 100%, ${
              value === 0 ? 100 : Math.max(0, 80 - (value / 36) * 20)
            }%)`,
      }}
      {...props}
    >
      {value === 0 ? "." : value}
    </div>
  );
};
